import { Portal } from '@gorhom/portal';
import useCopy from 'app/hooks/use-copy';
import React, {
  Ref,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Keyboard,
  NativeSyntheticEvent,
  Platform,
  StyleSheet,
  TextInputFocusEventData,
  TextInputKeyPressEventData,
  View,
  ViewProps,
  ViewStyle,
  LayoutChangeEvent,
  TextInput as RNTextInput,
} from 'react-native';
import Animated, {
  runOnJS,
  useAnimatedReaction,
} from 'react-native-reanimated';
import Close from '../Icons/Close';
import SearchIcon from '../Icons/Search';
import Reaction, { ReactionType } from '../Link/Reaction';
import Pressable from '../Pressable';
import Text, { TextType } from '../Text';
import TextInput from '../TextInput';
import ThemeView, { makeThemeStyle } from '../ThemeView';
import SearchSuggestions from './SearchSuggestions';
import { ActiveKey, SearchReactions } from './types';
import useStuffsQuery from './useStuffsQuery';
import useSearchInputStyles, {
  SEARCH_ICON_WIDTH,
} from './useSearchInputStyles';
import StuffListContext from '../StuffList/StuffListContext';
import { useResource } from 'app/hooks/use-resource/use-resource';
import useColorScheme from 'app/hooks/use-color-scheme';

interface Props {
  parsedQuery: ReturnType<typeof useStuffsQuery>['parsedQuery'];
  onChangeQuery: ReturnType<typeof useStuffsQuery>['onChangeQuery'];
  onChangeQueryKey: ReturnType<typeof useStuffsQuery>['onChangeQueryKey'];
  resetQuery: ReturnType<typeof useStuffsQuery>['resetQuery'];
  onFocusToggle?: (focus: boolean) => void;
  onCancel?: () => void;
  username: string;
  isVisitor: boolean;
  style?: ViewProps['style'];
  suggestionsStyle?: ViewStyle;
  showSuggestions?: boolean;
  onLayout?: ViewProps['onLayout'];
  presentedAnimatedValue: Animated.SharedValue<number>;
  focusedAnimatedValue: Animated.SharedValue<number>;
  showCancel?: boolean;
  suggestionsPortalName?: string;
  autoFocus?: boolean;
  containerWidth?: number;
  inputPointerEvents?: ViewProps['pointerEvents'];
  isBottomSheet?: boolean;

  // To control the search (needed in SearchSheetContent)
  search?: string;
  setSearch?: (search: string) => void;
}

function SearchInputFreeForm({
  parsedQuery,
  resetQuery,
  onChangeQuery,
  onChangeQueryKey,
  onFocusToggle,
  username,
  isVisitor,
  style,
  suggestionsStyle,
  showSuggestions = true,
  presentedAnimatedValue,
  focusedAnimatedValue,
  showCancel = true,
  containerWidth: providedContainerWidth,
  suggestionsPortalName,
  autoFocus,
  inputPointerEvents,
  isBottomSheet,
  onCancel: _onCancel,
  search: _providedSearch,
  setSearch: _providedSetSearch,
}: Props) {
  const copy = useCopy([
    'homeSearchPlaceholder',
    'homeSearchPlaceholderWithActiveSearch',
    'homeSearchCancel',
    'visitorSearchPlaceholder',
  ]);
  const [activeKey, setActiveKey] = useState<ActiveKey>(null);
  const [suggestionsVisible, _setSuggestionsVisible] = useState(false);
  const [searchSuggestion, setSearchSuggestion] = useState('');
  const [textWidth, setTextWidth] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const textInputRef = useRef<RNTextInput>();
  const { stuffTransitionValue } = useContext(StuffListContext);
  const colorScheme = useColorScheme();
  const { topic } = useResource('topic', parsedQuery.topicId);

  const [_search, _setSearch] = useState('');
  const search = _providedSearch || _search;
  const setSearch = useCallback(
    (newSearch: string) => {
      if (_providedSetSearch) {
        _providedSetSearch(newSearch);
      } else {
        _setSearch(newSearch);
      }
    },
    [_providedSetSearch],
  );

  const setSuggestionsVisible = useCallback(
    (visible: boolean) => {
      if (showSuggestions) {
        _setSuggestionsVisible(visible);
      }
    },
    [showSuggestions],
  );

  // ---------- Animated styles
  const {
    leftElementAnimatedStyle,
    rightElementAnimatedStyle,
    searchInputContainerStyle,
    clearButtonStyle,
    heartStyle,
    flagStyle,
    emphasisStyle,
    animatedInputStyle,
    cancelStyle,
    reactionsContainerStyle,
  } = useSearchInputStyles({
    parsedQuery,
    presentedAnimatedValue,
    focusedAnimatedValue,
    showCancel,
    textWidth,
    containerWidth: providedContainerWidth || containerWidth,
  });

  // ------- Callbacks

  const onLayout = useCallback(
    (event: LayoutChangeEvent) => {
      if (Platform.OS === 'ios' && containerWidth) {
        return;
      }

      setContainerWidth(event.nativeEvent.layout.width);
    },
    [containerWidth],
  );

  const updateSearch = useCallback(
    (term: string) => {
      onChangeQueryKey('filter[link.search]', term);
    },
    [onChangeQueryKey],
  );

  const onKeyPress = useCallback(
    (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
      const key = event.nativeEvent.key;

      if (key === 'ArrowDown' || key === 'ArrowUp') {
        setActiveKey(key);
      }
    },
    [],
  );

  const onBlur = useCallback(
    (event?: NativeSyntheticEvent<TextInputFocusEventData>) => {
      // If there's a related target, it means a button was clicked (or something else
      // that can get focus). We only want to blur if the background was clicked.
      if (event) {
        if (event.nativeEvent.relatedTarget) {
          return;
        }

        event.preventDefault();
        event.stopPropagation();
      }

      if (onFocusToggle) {
        onFocusToggle(false);
      }

      if (isVisitor) {
        setTimeout(() => {
          // LayoutAnimation.configureNext(RNSpringConfig);
          setSuggestionsVisible(false);
        }, 100);
      }
    },
    [onFocusToggle, isVisitor, setSuggestionsVisible],
  );

  const onFocus = useCallback(() => {
    if (onFocusToggle) {
      onFocusToggle(true);
    }

    if (search) {
      setSuggestionsVisible(true);
    }
  }, [onFocusToggle, search, setSuggestionsVisible]);

  const onChangeText = useCallback(
    (text: string) => {
      setSearch(text);

      if (text && !suggestionsVisible) {
        setSuggestionsVisible(true);
      } else if (!text) {
        setSuggestionsVisible(false);
      }
    },
    [suggestionsVisible, setSearch, setSuggestionsVisible],
  );

  const onSubmitEditing = useCallback(() => {
    updateSearch(searchSuggestion || search);
  }, [search, searchSuggestion, updateSearch]);

  const onCancel = useCallback(
    (callParent = true) => {
      setSearch(parsedQuery.search);
      setSuggestionsVisible(false);
      Keyboard.dismiss();

      if (_onCancel && callParent) {
        _onCancel();
      }

      onBlur();
    },
    [_onCancel, setSearch, parsedQuery, onBlur, setSuggestionsVisible],
  );

  const onClear = useCallback(() => {
    setSearch('');
    resetQuery();

    if (presentedAnimatedValue.value === 1) {
      if (textInputRef.current) {
        textInputRef.current.focus();
      }
    }
  }, [resetQuery, setSearch, presentedAnimatedValue]);

  // ------- Effects

  // Update search when the query changes (after selecting a suggestion
  // for example)
  useEffect(() => {
    setSearch(parsedQuery.search || topic?.attributes?.title || '');
  }, [parsedQuery.search, topic?.attributes?.title, setSearch]);

  // Immediately unset activeKey so it fires once
  useEffect(() => {
    if (activeKey) {
      setActiveKey(null);
    }
  }, [activeKey]);

  // Clear search on query change
  useEffect(() => {
    Keyboard.dismiss();
  }, [parsedQuery]);

  useAnimatedReaction(
    () => presentedAnimatedValue.value,
    (current, previous) => {
      // Hide suggestions when we animate away
      if (current === 0 && previous !== 0) {
        runOnJS(setSuggestionsVisible)(false);
      }

      // Simulate the cancel button when the sheet is animated away
      // (which the sheet handles). This handles the case where toAnimate
      // isn't fired properly in the bottomsheet and so onCancel isn't called.
      // onCancel handles things like updating the `search`, so its crucial
      // that whenever the searchinput goes into a canceled state, onCancel
      // is called.
      if (current !== 1 && previous === 1 && parsedQuery.isActive) {
        runOnJS(onCancel)(false);
      }
    },
    [setSuggestionsVisible, parsedQuery.isActive, onCancel],
  );

  useEffect(() => {
    if (!stuffTransitionValue) {
      return;
    }

    stuffTransitionValue.value = suggestionsVisible ? 0 : 1;
  }, [stuffTransitionValue, suggestionsVisible]);

  // ------------ Elements

  const reactions: SearchReactions = useMemo(
    () => [
      {
        selected: parsedQuery.reactionLove,
        type: ReactionType.reactionLove,
        onSelect: () =>
          onChangeQueryKey(
            'filter[link.reaction_love]',
            parsedQuery.reactionLove ? undefined : true,
          ),
      },
      {
        selected: parsedQuery.reactionFlag,
        type: ReactionType.reactionFlag,
        onSelect: () =>
          onChangeQueryKey(
            'filter[link.reaction_flag]',
            parsedQuery.reactionFlag ? undefined : true,
          ),
      },
      {
        selected: parsedQuery.reactionEmphasis,
        type: ReactionType.reactionEmphasis,
        onSelect: () =>
          onChangeQueryKey(
            'filter[link.reaction_emphasis]',
            parsedQuery.reactionEmphasis ? undefined : true,
          ),
      },
    ],
    [onChangeQueryKey, parsedQuery],
  );

  let suggestions = null;

  if (suggestionsVisible) {
    suggestions = (
      <SearchSuggestions
        activeKey={activeKey}
        search={search}
        onChangeQuery={onChangeQuery}
        onSelectSuggestion={updateSearch}
        onSetSuggestion={setSearchSuggestion}
        username={username}
        isVisitor={isVisitor}
        style={suggestionsStyle}
      />
    );

    if (suggestionsPortalName) {
      suggestions = (
        <Portal hostName={suggestionsPortalName}>{suggestions}</Portal>
      );
    }
  }

  return (
    <>
      <ThemeView style={[styles.container]}>
        <TextInput
          inputStyle={[styles.inputStyle, animatedInputStyle]}
          style={style}
          horizontalPadding={false}
          inputContainerStyle={[
            styles.inputContainer,
            searchInputContainerStyle,
          ]}
          inputPointerEvents={inputPointerEvents}
          onTextWidthChange={setTextWidth}
          blurOnSubmit={false}
          value={search}
          onChangeText={onChangeText}
          onLayout={onLayout}
          placeholder={
            parsedQuery.search
              ? copy.homeSearchPlaceholderWithActiveSearch
              : isVisitor
              ? copy.visitorSearchPlaceholder
              : copy.homeSearchPlaceholder
          }
          autoCapitalize="none"
          onSubmitEditing={onSubmitEditing}
          returnKeyType="search"
          enablesReturnKeyAutomatically
          onKeyPress={onKeyPress}
          autoFocus={autoFocus}
          onBlur={onBlur}
          onFocus={onFocus}
          ref={textInputRef}
          isBottomSheet={isBottomSheet}
          leftElement={
            <View style={[styles.searchIconButton]}>
              <SearchIcon
                color="gray11"
                width={SEARCH_ICON_WIDTH}
                height={SEARCH_ICON_WIDTH}
              />
            </View>
          }
          leftElementStyle={[styles.leftElement, leftElementAnimatedStyle]}
          rightElementStyle={rightElementAnimatedStyle}
          rightElement={
            <>
              <Animated.View
                style={[styles.reactions, reactionsContainerStyle]}
              >
                {reactions.map(({ type, onSelect, selected }, index) => (
                  <Reaction
                    selected={selected || false}
                    onSelect={onSelect}
                    style={[
                      styles.reaction,
                      index < reactions.length - 1
                        ? styles.reactionMargin
                        : undefined,
                      index === 0 && heartStyle,
                      index === 1 && flagStyle,
                      index === 2 && emphasisStyle,
                    ]}
                    type={type}
                    key={type}
                    transparentBackground={colorScheme === 'dark'}
                  />
                ))}
              </Animated.View>
              <Animated.View
                style={[styles.clearSearchButton, clearButtonStyle]}
              >
                <Pressable
                  style={styles.closeButton}
                  onPress={onClear}
                  hitSlop={{
                    top: 8,
                    left: 8,
                    right: 8,
                    bottom: 8,
                  }}
                >
                  <Close
                    width={12}
                    height={12}
                    color={colorScheme === 'dark' ? 'gray12' : 'gray9'}
                  />
                </Pressable>
              </Animated.View>
            </>
          }
        />
        {showCancel && (
          <Pressable style={[styles.cancel, cancelStyle]} onPress={onCancel}>
            <Text type={TextType.body} color="blue9">
              {copy.homeSearchCancel}
            </Text>
          </Pressable>
        )}
      </ThemeView>
      {suggestions}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    alignSelf: 'stretch',
    borderRadius: 10,
    padding: 0,
    position: 'relative',
  },
  inputStyle: {
    alignSelf: 'center',
    // On web, minWidth by default is "auto". This means it can't be smaller than
    // its content. But in this case we want to shrink it to its intrinsic size,
    // which these seems to do, rather than overflowing the container. We also
    // set overflow mode so it shows an ellipsis when necessary.
    minWidth: 0,
    // On iOS, this happens by default
    ...(Platform.OS === 'web' && { textOverflow: "'ellipsis'" }),
  },
  inputContainer: {
    height: 40,
    borderRadius: 10,
  },
  leftElement: {
    alignItems: 'flex-end',
  },
  reactions: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: 1,
    gap: 2,
  },
  reaction: {
    height: 28,
    width: 28,
  },
  reactionMargin: {
    // marginRight: 2,
  },
  searchIconButton: {
    // Elements aren't aligned centralled, so we have to make it the height
    // of the input. I rememner there being a reason why we couldn't just
    // centralize.
    height: 40,
    width: SEARCH_ICON_WIDTH,
    alignItems: 'center',
    justifyContent: 'center',
  },
  ...makeThemeStyle(
    'searchIconButtonActive',
    {},
    {
      backgroundColor: 'gray6',
    },
  ),
  cancel: {
    marginLeft: 12,
  },
  clearSearchButton: {
    position: 'absolute',
    right: 0,
    top: 6,
    padding: 8,
  },
  closeButton: Platform.select({
    web: {
      padding: 12,
      margin: -12,
    },
    default: {},
  }),
});

export default SearchInputFreeForm;
