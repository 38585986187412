import { Portal, PortalProvider } from '@gorhom/portal';
import { blackA } from '@radix-ui/colors';
import Toast from 'app/hooks/use-toast';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import {
  Modal as RNModal,
  ModalProps,
  Platform,
  Pressable,
  StyleSheet,
  View,
  ScrollView,
} from 'react-native';
import Animated, {
  interpolateColor,
  runOnJS,
  SharedValue,
  useAnimatedReaction,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  WithSpringConfig,
} from 'react-native-reanimated';
import BlurView from './BlurView';
import KeyboardAvoidingView from './KeyboardAvoidingView';
import { ReactNativeKeysKeyCode, useHotkey } from 'react-native-hotkeys';

interface Props extends ModalProps {
  darkenBackground?: boolean;
  centered?: boolean;
  animatedValue?: SharedValue<number>;
  handleAnimations?: boolean;
  animateContent?: boolean;
  keyboardAvoidingView?: boolean;
  onRequestClose?: () => void;
  scrollable?: boolean;
  hostName?: string;
  usePortal?: boolean;
}

export const modalAnimationConfig: WithSpringConfig = {
  stiffness: 50,
  mass: 0.1,
  damping: 20,
  overshootClamping: true,
};

export const ModalContext = React.createContext<{
  onRequestClose?: () => void;
}>({});

function toggleBodyLock(lock: boolean) {
  if (global.__IS_SERVER__) {
    return;
  }

  if (lock) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }
}

function Modal({
  darkenBackground,
  centered,
  animatedValue: _animatedValue,
  handleAnimations = true,
  animateContent = true,
  keyboardAvoidingView = true,
  scrollable,
  hostName,
  usePortal = true,
  presentationStyle = 'overFullScreen',
  ...props
}: Props) {
  const uncontrolledAnimatedValue = useSharedValue(0);

  const animatedValue = useMemo(
    () => _animatedValue || uncontrolledAnimatedValue,
    [_animatedValue, uncontrolledAnimatedValue],
  );

  useAnimatedReaction(
    () => animatedValue.value,
    (currentValue, previousValue) => {
      if (!global.__IS_SERVER__ && Platform.OS === 'web') {
        if (currentValue > 0 && previousValue === 0) {
          runOnJS(toggleBodyLock)(true);
        } else if (currentValue < 1 && previousValue === 1) {
          runOnJS(toggleBodyLock)(false);
        }
      }
    },
  );

  useEffect(() => {
    if (!handleAnimations) {
      return;
    }

    if (props.visible) {
      animatedValue.value = withSpring(1, modalAnimationConfig);
    }

    return () => {
      animatedValue.value = withSpring(0, modalAnimationConfig);
    };
  }, [props.visible, animatedValue, handleAnimations]);

  const backdropStyle = useAnimatedStyle(() => {
    if (Platform.OS === 'web') {
      return {
        backgroundColor: darkenBackground
          ? animatedValue
            ? interpolateColor(
                animatedValue.value,
                [0, 1],
                ['transparent', blackA.blackA8],
              )
            : blackA.blackA8
          : 'transparent',
      };
    }

    return {
      opacity: animatedValue ? animatedValue.value : 1,
    };
  }, [animatedValue, darkenBackground]);

  const contentStyle = useAnimatedStyle(() => {
    if (!animateContent) {
      return {};
    }
    return {
      opacity: animatedValue ? animatedValue.value : 1,
    };
  }, [animatedValue, animateContent]);

  const onRequestClose = useCallback(() => {
    if (!handleAnimations) {
      if (props.onRequestClose) {
        props.onRequestClose();
      }
      return;
    }

    animatedValue.value = withSpring(0, modalAnimationConfig, () => {
      if (props.onRequestClose) {
        runOnJS(props.onRequestClose)();
      }
    });
  }, [props.onRequestClose, animatedValue, handleAnimations]);

  const onPressEscape = useCallback(() => {
    onRequestClose();
  }, [onRequestClose]);

  useHotkey(ReactNativeKeysKeyCode.Escape, () => {
    onPressEscape();
  });

  if (!props.visible) {
    return null;
  }

  const modal = (
    <ModalContext.Provider value={{ onRequestClose }}>
      <View
        style={[StyleSheet.absoluteFill, { zIndex: 10 }]}
        pointerEvents="box-none"
      >
        {presentationStyle === 'overFullScreen' && (
          <Animated.View
            style={[
              styles.modalBackdrop,
              darkenBackground && styles.modalBackdropDark,
              backdropStyle,
            ]}
          >
            {darkenBackground && (
              <BlurView
                amount={12}
                style={styles.modalBackdrop}
                animatedValue={animatedValue}
                blurType="ultraThinMaterialDark"
              />
            )}
          </Animated.View>
        )}
        {props.onRequestClose && presentationStyle === 'overFullScreen' && (
          <Pressable
            style={StyleSheet.absoluteFillObject}
            onPress={onRequestClose}
          />
        )}
        <Animated.View
          style={[styles.contentContainer, contentStyle]}
          pointerEvents="box-none"
        >
          {scrollable ? (
            <ScrollView
              centerContent={centered}
              style={[
                {
                  flex: 1,
                },
              ]}
              contentContainerStyle={{
                alignItems: 'center',
              }}
              pointerEvents="box-none"
            >
              {props.onRequestClose &&
                presentationStyle === 'overFullScreen' && (
                  <Pressable
                    style={StyleSheet.absoluteFillObject}
                    onPress={onRequestClose}
                  />
                )}
              {props.children}
            </ScrollView>
          ) : keyboardAvoidingView ? (
            <KeyboardAvoidingView
              behavior="padding"
              pointerEvents="box-none"
              style={centered && styles.centered}
            >
              {props.children}
            </KeyboardAvoidingView>
          ) : (
            <View style={centered && styles.centered}>{props.children}</View>
          )}
        </Animated.View>
        <PortalProvider>
          <Toast portalHostName="modal" />
        </PortalProvider>
      </View>
    </ModalContext.Provider>
  );

  if (usePortal && presentationStyle === 'overFullScreen') {
    return (
      <Portal hostName={hostName} {...props}>
        {modal}
      </Portal>
    );
  }

  if (presentationStyle !== 'overFullScreen') {
    return (
      <RNModal presentationStyle={presentationStyle} {...props}>
        {modal}
      </RNModal>
    );
  }

  return modal;
}
const styles = StyleSheet.create({
  modalBackdrop: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  modalBackdropDark: {
    backgroundColor: blackA.blackA8,
  },
  contentContainer: { flex: 1 },
  centered: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default Modal;
