import { blackA } from '@radix-ui/colors';
import { Link, LinkData as LinkDataType } from 'app/hooks/use-resource/types';
import { useUpdate } from 'app/hooks/use-resource/use-resource';
import React, {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  LayoutRectangle,
  Platform,
  StyleSheet,
  useWindowDimensions,
  View,
} from 'react-native';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';
import Animated, {
  interpolateColor,
  runOnJS,
  SharedValue,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import { ReactNativeKeysKeyCode, useHotkey } from 'react-native-hotkeys';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import BlurView from '../BlurView';
import LinkData from '../LinkData';
import Pressable from '../Pressable';
import LinkExpandedContext from '../StuffList/LinkExpandedContext';
import { CONTENT_PADDING_EXPANDED_MOBILE } from '../StuffList/useLayoutInfo';
import LinkActions from './LinkActions';
import Reactions from './Reactions';
import useLinkExpandedGestures from './useLinkExpandedGestures';
import useLinkStyles, { LinkDataTransform } from './useLinkStyles';
import { measure } from 'app/measure';
import { format, isThisWeek, isThisYear, isToday } from 'date-fns';
import Text, { TextType } from '../Text';
import { light } from 'app/constants/colors';
import useStuffsQuery from '../SearchSheet/useStuffsQuery';
import useColorScheme from 'app/hooks/use-color-scheme';

interface Props {
  onClose: () => void;
  isMobile: boolean;
  link: Link;
  linkData: LinkDataType;
  updateLink: ReturnType<typeof useUpdate<'link', Link>>;
  isVisitor: boolean;
  windowDimensions: ReturnType<typeof useWindowDimensions>;
  linkDataCollapsed: React.ReactNode;
  tapGesture: typeof Gesture;
  expandedLinkDataTransform: SharedValue<LinkDataTransform>;
  collapsedRef: RefObject<View>;
  scrollViewOffset: SharedValue<number>;
  onChangeQuery: ReturnType<typeof useStuffsQuery>['onChangeQuery'];
}
const DEBUG_ANIMATIONS = false;

const config = Platform.select({
  ios: {
    stiffness: 300,
    mass: DEBUG_ANIMATIONS ? 10 : 1,
    damping: DEBUG_ANIMATIONS ? 25 : 25,
    restDisplacementThreshold: 0.001,
    restSpeedThreshold: 0.01,
  },
  default: {
    stiffness: 50,
    mass: 0.1,
    damping: 20,
    restDisplacementThreshold: 0.001,
    restSpeedThreshold: 0.01,
  },
});

const LinkExpanded = ({
  onClose: _onClose,
  isMobile,
  link,
  linkData,
  updateLink,
  isVisitor,
  windowDimensions,
  linkDataCollapsed,
  expandedLinkDataTransform,
  collapsedRef,
  scrollViewOffset,
  tapGesture,
  onChangeQuery,
}: Props) => {
  const insets = useSafeAreaInsets();
  const [containerLayout, setContainerLayout] =
    useState<LayoutRectangle | null>(null);
  const expandedRef = useRef<View>(null);
  const [open, setOpen] = useState(true);
  const { setLinkIsExpanded } = useContext(LinkExpandedContext);
  const colorScheme = useColorScheme();

  useEffect(() => {
    if (!global.__IS_SERVER__ && Platform.OS === 'web') {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      setLinkIsExpanded(false);
    };
  }, [setLinkIsExpanded]);

  // Capture the offset on mount
  const scrollViewInitialOffset = useSharedValue<number | null>(null);

  const onClose = useCallback(async () => {
    if (!global.__IS_SERVER__ && Platform.OS === 'web') {
      document.body.style.overflow = 'auto';
    }

    scrollViewInitialOffset.value = scrollViewOffset.value;

    const { pageX: expandedPageX, pageY: expandedPageY } = await measure(
      expandedRef,
    );

    const { pageX: collapsedPageX, pageY: collapsedPageY } = await measure(
      collapsedRef,
    );

    // NOTE: We don't update the dimensions of the objects because they're effected by the
    // existing transformations. The measurements from the initial layout are correct.
    const to = {
      ...expandedLinkDataTransform.value,
      progress: 0,
      x: collapsedPageX - expandedPageX + expandedLinkDataTransform.value.x,
      y: collapsedPageY - expandedPageY + expandedLinkDataTransform.value.y,
      scale: 1,
    };

    setTimeout(() => {
      setTimeout(() => setLinkIsExpanded(false), 10);
    });

    // Animate to the closed state
    expandedLinkDataTransform.value = withSpring(to, config, () => {
      runOnJS(_onClose)();

      // Reset them when the animation has finished
      expandedLinkDataTransform.value = {
        x: 0,
        y: 0,
        progress: 0,
        scale: 1,
        expandedWidth: 1,
        expandedHeight: 1,
        collapsedWidth: 1,
        collapsedHeight: 1,
      };
    });

    setOpen(false);
  }, [
    _onClose,
    expandedLinkDataTransform,
    collapsedRef,
    setLinkIsExpanded,
    scrollViewOffset,
    scrollViewInitialOffset,
  ]);

  const onLayout = useCallback(async () => {
    if (!expandedRef.current) {
      return;
    }

    const {
      pageX: expandedPageX,
      pageY: expandedPageY,
      width: expandedWidth,
      height: expandedHeight,
    } = await measure(expandedRef);

    const {
      pageX: collapsedPageX,
      pageY: collapsedPageY,
      width: collapsedWidth,
      height: collapsedHeight,
    } = await measure(collapsedRef);

    const from = {
      progress: 0,
      x: collapsedPageX - expandedPageX,
      y: collapsedPageY - expandedPageY,
      scale: 1,
      expandedWidth,
      expandedHeight,
      collapsedWidth,
      collapsedHeight,
    };

    const to = {
      x: 0,
      y: 0,
      scale: 1,
      progress: 1,
      expandedWidth,
      expandedHeight,
      collapsedWidth,
      collapsedHeight,
    };

    // Reset to collapsed current position
    expandedLinkDataTransform.value = from;

    // Animate to the open state
    expandedLinkDataTransform.value = withSpring(to, config);

    setLinkIsExpanded(true);
  }, [expandedLinkDataTransform, collapsedRef, setLinkIsExpanded]);

  const {
    dragOffset,
    dragGesture,
    dragSnappedUp,
    dragSnappedDown,
    dragHeightDiff,
  } = useLinkExpandedGestures({
    containerLayout,
    onClose,
    enabled: open,
    expandedPosition: expandedLinkDataTransform,
  });

  const {
    linkActionsStyle,
    linkDataCollapsedAnimatedStyle,
    linkDataExpandedAnimatedStyle,
    reactionsStyle,
  } = useLinkStyles({
    expandedPosition: expandedLinkDataTransform,
    dragSnappedUp,
    dragSnappedDown,
    dragOffset,
  });

  const scrollOffsetStyle = useAnimatedStyle(() => {
    if (scrollViewInitialOffset.value === null) {
      return {};
    }

    return {
      transform: [
        {
          translateY: scrollViewInitialOffset.value - scrollViewOffset.value,
        },
      ],
    };
  }, [scrollViewInitialOffset, scrollViewOffset]);

  const backdropStyle = useAnimatedStyle(() => {
    if (Platform.OS === 'web') {
      return {
        backgroundColor: interpolateColor(
          expandedLinkDataTransform.value.progress,
          [0, 1],
          ['transparent', blackA.blackA8],
        ),
      };
    }

    return {
      opacity: expandedLinkDataTransform.value.progress,
    };
  }, [expandedLinkDataTransform]);

  useHotkey(ReactNativeKeysKeyCode.Escape, () => {
    onClose();
  });

  const hasReactions =
    link.attributes.reaction_flag ||
    link.attributes.reaction_love ||
    link.attributes.reaction_emphasis;
  const shouldBreak = isMobile && (!isVisitor || hasReactions);

  const dateHeader = useMemo(() => {
    if (!link) {
      return '';
    }

    // TODO: The API doesn't return UTC timezones (as sqlite doesn't have timezone
    // support), so here we're just forcing the iso8601 string into UTC. We should do
    // better...
    const date = new Date(link.attributes.created_at + '+00:00');
    let top = '';
    let bottom = '';

    if (isToday(date)) {
      top = 'Today';
      bottom = format(date, 'p');
    } else if (isThisWeek(date)) {
      top = format(date, 'eeee');
      bottom = format(date, 'p');
    } else if (isThisYear(date)) {
      top = `${format(date, 'eeee')}${shouldBreak ? '\n' : ' '}${format(
        date,
        'd MMM',
      )}`;
    } else {
      top = format(date, 'MMM Y ');
    }

    return (
      <>
        {top}
        {bottom && shouldBreak ? '\n' : ' '}
        <Text
          type={TextType.footnote}
          color={light.LayeredWhite}
          style={styles.dateText}
        >
          {bottom.toLowerCase()}
        </Text>
      </>
    );
  }, [link, shouldBreak]);

  return (
    <View
      style={StyleSheet.absoluteFillObject}
      pointerEvents={open ? 'auto' : 'none'}
    >
      <GestureDetector gesture={dragGesture}>
        <Animated.View
          style={[
            styles.gestureContainer,
            {
              paddingTop: dragHeightDiff ? insets.top + 80 : 0,
            },
          ]}
        >
          <Animated.View
            style={[
              StyleSheet.absoluteFillObject,
              styles.modalBackdropDark,
              backdropStyle,
            ]}
          >
            <BlurView
              amount={12}
              style={StyleSheet.absoluteFillObject}
              animatedValue={expandedLinkDataTransform}
              animatedValueProp="progress"
              blurType={colorScheme === 'dark' ? 'dark' : 'light'}
            />
          </Animated.View>
          <Pressable style={StyleSheet.absoluteFillObject} onPress={onClose} />
          <Animated.View
            style={[
              styles.expandedScrollViewContent,
              isMobile && styles.expandedScrollViewContentMobile,
              scrollOffsetStyle,
            ]}
            pointerEvents="box-none"
            onLayout={(event) => {
              if (!containerLayout) {
                setContainerLayout(event.nativeEvent.layout);
              }
            }}
          >
            <View
              style={styles.linkDataExpandedContainer}
              pointerEvents="box-none"
            >
              <View style={styles.header} pointerEvents="box-none">
                <View
                  style={[
                    styles.reactionsContainer,
                    shouldBreak && styles.reactionsContainerMobile,
                  ]}
                >
                  <Reactions
                    style={[styles.reactions, reactionsStyle]}
                    link={link}
                    update={updateLink}
                    expanded
                    disabled={isVisitor}
                  />
                </View>
                <Animated.View
                  style={[
                    styles.dateHeader,
                    shouldBreak && styles.dateHeaderMobile,
                    reactionsStyle,
                  ]}
                  pointerEvents="box-none"
                >
                  <Text
                    type={TextType.footnoteEmphasized}
                    color={light.LayeredWhite}
                    style={styles.dateText}
                  >
                    {dateHeader}
                  </Text>
                </Animated.View>
                <View
                  style={[
                    styles.headerRight,
                    shouldBreak && styles.headerRightMobile,
                  ]}
                />
              </View>
              <View pointerEvents="box-none">
                <LinkData
                  linkDataId={link.relationships.link_data.data.id}
                  width={windowDimensions.width - 300}
                  onLayout={onLayout}
                  ref={expandedRef}
                  expanded
                  style={[
                    styles.linkDataExpanded,
                    isMobile && styles.linkDataExpandedMobile,
                    linkDataExpandedAnimatedStyle,
                  ]}
                  // As the image will have already been loaded
                  useBlurHash={false}
                  onChangeQuery={onChangeQuery}
                />
                <Animated.View
                  style={[
                    styles.linkDataCollapsed,
                    linkDataCollapsedAnimatedStyle,
                  ]}
                  pointerEvents="none"
                >
                  {linkDataCollapsed}
                </Animated.View>
              </View>
            </View>
            <Animated.View
              style={[
                linkActionsStyle,
                styles.linkActions,
                isMobile && styles.linkActionsMobile,
              ]}
              pointerEvents="auto"
            >
              <LinkActions
                linkData={linkData}
                link={link}
                isVisitor={isVisitor}
                onClose={onClose}
              />
            </Animated.View>
          </Animated.View>
        </Animated.View>
      </GestureDetector>
    </View>
  );
};

const styles = StyleSheet.create({
  gestureContainer: {
    justifyContent: 'center',
    flex: 1,
    paddingHorizontal: CONTENT_PADDING_EXPANDED_MOBILE,
  },
  expandedScrollViewContent: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  expandedScrollViewContentMobile: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  linkDataExpandedContainer: {
    // So its higher than the actions
    zIndex: 2,
  },
  linkDataExpanded: {
    marginRight: 12,
  },
  linkDataExpandedMobile: {
    marginRight: 0,
    marginBottom: 12,
  },
  linkDataCollapsed: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  linkActions: {
    zIndex: 1,
    marginTop: 54,
  },
  linkActionsMobile: {
    zIndex: 1,
    marginTop: 0,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    pointerEvents: 'box-none',
  },
  reactionsContainer: {
    flex: 1,
    height: 54,
  },
  reactionsContainerMobile: {
    flex: 0,
    minWidth: 136,
  },
  dateHeader: {
    flex: 1,
    alignItems: 'center',
  },
  dateHeaderMobile: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerRight: {
    flex: 1,
    height: 20,
  },
  headerRightMobile: {
    flex: 0,
    minWidth: 136,
  },
  reactions: {
    alignSelf: 'flex-start',
    marginBottom: 6,
    marginLeft: -4,
  },
  modalBackdropDark: {
    backgroundColor: blackA.blackA8,
  },
  dateText: {
    textAlign: 'center',
    flexDirection: 'row',
  },
});

export default LinkExpanded;
